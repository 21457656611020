<template>
  <!-- 企业担保模块 -->
  <div class="enterpriseGuarantee">
    <div class="item">
      <div class="title">
        <div class="headTop">
          <title-center name="企业担保" :rules="dictType!=='3'&&dictType!=='4'" ></title-center>
          <div class="addbtn" v-if="!isDetail">
          <div class="addbutton" @click="addEnterprise">
            <i class="el-icon-plus"></i>
          </div>
        </div>
        </div>

      </div>
      <!-- 表格录入数据 -->
      <base-table
        :columns="enterpriseGuarantee"
        :tableAttrs="{
          data: enterpriseData,
          stripe: true,
        }"
        :isCaculateHeight="false"
        :showPage="false"
      >
        <template slot="index" slot-scope="scope">{{
          scope.$index + 1
        }}</template>
        <template slot="action" slot-scope="scope" v-if="!isDetail">
          <IconButton
            @click="removeEnterprise(scope.$index)"
            content="删除"
            icon="iconfont iconshanchu1"
          ></IconButton>
        </template>
      </base-table>
    </div>
    <!-- 选择企业担保数据 -->
    <add-enterprise
      :visible="enterpriseState"
      @emitVisible="enterpriseState = false"
      :enterpriseData="enterpriseData"
      @addEnterprise="addEnterpriseData"
    ></add-enterprise>
  </div>
</template>
<script>
import baseTable from '@/components/common/table/base-table/base-table.vue'
import TitleCenter from '../../components/title-center.vue'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import { enterpriseForm } from '../utils/config'
import AddEnterprise from './add-enterprise.vue'
// import { contactApi } from '@/api/contactApi'

export default {
  components: { baseTable, TitleCenter, IconButton, AddEnterprise },
  name: 'enterpriseGuarantee',
  props: {
    // secondDefaultEnterpriseData: Object,
    enterpriseData: Array,
    mainPartData: Array,
    enterpriseSelect: String,
    subject: Array,
    dictType: String,
    financeType: String, // 融资模式
    isDetail: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      enterpriseState: false,
      loanAssistance: '' // 申请类型
    }
  },
  computed: {
    enterpriseGuarantee () {
      return enterpriseForm(this)
    }
  },

  watch: {
    enterpriseSelect (val) { // 回显申请类型
      if (val) {
        this.loanAssistance = val
      }
    }
  },
  methods: {
    // 企业提示
    titleTipsEnterprise () {
      if (this.dictType === '4') {
        return '向深圳稳诚一方、前海一方提供公司无限连带保证反担保，签署《公司最高额保证反担保函》'
      } else if (this.dictType === '3') {
        return '关联企业向稳惠保理提供公司无限连带保证担保责任并签署【最高额保证合同】'
      } else if (this.dictType === '1') {
        if (this.financeType === 'ONCE_MODEL') {
          // 建行一级
          return '向深圳稳诚一方提供公司最高额连带保证责任连带保证反担保责任并签署【公司最高额保证反担保函】、【股东会决议】'
        } else { // 建行二级
          return '向深圳稳诚一方提供公司连带保证反担保责任并签署【公司最高额保证反担保函】、【股东会决议】'
        }
      } else {
        return ''
      }
    },
    // 新增企业担保
    addEnterprise () {
      // if (!this.loanAssistance) {
      //   return this.warning('请先选择企业担保签约主体')
      // } else {

      // }
      this.enterpriseState = true
    },
    // 选择之后设置参数
    changeType (data, arr, index) {
      this.addEnterpriseData(this.enterpriseData, 'change')
    },
    // 新增企业担保数据
    addEnterpriseData (data = [], type) {
      console.log(data, '需要处理的数据')
      const newData = JSON.parse(JSON.stringify(data))
      const [selectData] = this.mainPartData.filter(
        (item) => item.keyId === this.loanAssistance
      )
      if (Array.isArray(newData)) {
        newData.forEach((item) => {
          if (selectData) {
            item.loanAssistanceOrg = selectData.enterpriseName
            item.loanAssistanceOrgId = selectData.keyId
          } else {
            item.loanAssistanceOrg = null
            item.loanAssistanceOrgId = null
          }
          // 修改担保主体时,处理当前已添加的表格项 无需重新赋值姓名key
          if (type !== 'change') {
            item.companyName = item.enterpriseName
            item.companyId = item.keyId
            item.nsrsbh = item.creditCode
          }
        })
      }
      this.$emit('changeEnterpriseData', JSON.parse(JSON.stringify(newData)))
    },
    // 删除企业担保数据
    removeEnterprise (index) {
      this.enterpriseData.splice(index, 1)
      if (this.enterpriseData.length === 0) {
        this.loanAssistance = ''
      }
    }
  }
}
</script>
<style lang="scss" src='../index.scss' scoped></style>
